import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

import * as Sentry from "@sentry/vue";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

import { setupI18n } from '@/i18n';
import VueViewer from 'v-viewer'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue3Storage from "vue3-storage";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    const i18n = setupI18n({
        legacy: true,
        locale: props.initialPage.props.locale,
        fallbackLocale: 'en',
        messages: {}
      })

    Sentry.init({
      app,
      dsn: "https://447bc91739f599b785dc0b4bffa399ec@o391730.ingest.sentry.io/4506583899176960",
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      environment: import.meta.env.VITE_SENTRY_ENV,
      beforeSend(event) {
        var errorType = event.exception.values[0].type;
        if (errorType === 'ChunkLoadError')
        {
          window.location.reload();
          return null;
        }
        return event;
      },
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [
            'localhost',
            'photasky.net',
            /^\//,
          ],
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
    });

    app.use(plugin)
       .use(ZiggyVue)
       .use(VueViewer)
       .use(VueLazyload, {
          lazyComponent: true
        })
       .use(Vue3Storage)
       //.use(VueMeta)
       .use(VueSweetalert2)
       .use(i18n)
       .component('font-awesome-icon', FontAwesomeIcon)
       .mount(el);

      app.config.globalProperties.$helpers = {
      }

      app.config.globalProperties.$filters = {
        formatDate(value) {
          let date = new Date(value);
          return date.toLocaleDateString();
        },
        formatDateTime(value) {
          let date = new Date(value);
          return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        },
        formatCurrency(value) {
          return parseFloat(value).toLocaleString(undefined, { style: 'currency', currency: 'EUR' });
        },
        checkUnknown(value, component) {
          if ((!value) || (value == 'null')) {
            return component.$i18n.t('Unknown');
          }
          return value;
        },
        checkNull(value) {
          if ((!value) || (value == 'null')) {
            return '-';
          }
          return value;
        },
        checkFoundSelf(value, component) {
          if (value === 'SELF') {
            return component.$i18n.t('you');
          }
          return value;
        },
        hideNull(value) {
          if ((!value) || (value == 'null')) {
            return "�";
          }
          return value;
        }
      }

    return app;
  },
  progress: {
    color: '#4B5563',
  },
});
